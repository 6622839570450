import { useMyContext } from '../../../contexts/StateHolder';
import RenderItem from '../RenderItems/RenderItem';
import CategoryTitle from '../CategoryTitles/CategoryTitle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAssets } from '../../../scripts/dataHandlers';
import { createToken } from '../../../scripts/utils';
import * as classes from './ChosenCategoryGrid.module.css';
import CategoryTitle2 from '../CategoryTitles/CategoryTitle2/CategoryTitle2';

const RenderChosenCategoryGrid = (props) => {
  // List of usable category components
  const components = {
    CategoryTitle,
    CategoryTitle2,
  };

  // Destructure props.settings
  const {
    routes,
    assetProperty,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,

    maintainImageAspectRatio,
  } = props.settings;

  // Destructure props.styles
  const { styles } = props.styles;

  // Bring stateholders from context
  const {
    chosenCategory,
    allCategories,
    key,
    language,
    organizationId,
    user,
    setChosenTab,
    chosenTab,
  } = useMyContext();

  const [items, setItems] = useState([]);

  const [categoryName, setCategoryName] = useState([]);

  const { asset } = useParams();
  console.log('chosenCategory', chosenCategory, allCategories);

  useEffect(() => {
    async function getData() {
      try {
        // Call createToken function to create new token from given data
        const token = createToken(organizationId, asset, key);

        // Call getAssets datahandler to get list of all items from category
        const itemList = await getAssets(
          organizationId,
          asset,
          token,
          language,
          assetProperty,
          user
        );
        console.log(itemList, 'itemlist');
        // Find categoryname by asset id number from itemList and set it in stateholder of categoryName
        setCategoryName(
          itemList[0].folders.find(
            (category) => Number(category.id) === Number(asset)
          ).name
        );

        // Set items in stateHolder
        setItems(itemList);
      } catch (err) {
        console.log(err);
      }
    }
    if (allCategories?.length > 0) {
      getData();
    }
    return () => {
      console.log(chosenTab);
      // Unbind the event listener on clean up
      setChosenTab(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories, language, chosenCategory, asset]);

  const renderChosenCategoryGrid = () => {
    // Map through category of items, showing clickable image of each item
    return items.map((item, i) => {
      let pushRoute = '';
      if (item.isSerie === true || item.series?.length > 0) {
        pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`; //${item.series[0].id}${item?.series[1]?.id ? "/"+item.series[1].id : ""}`
      } else {
        pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}`;
      }
      return (
        <RenderItem
          key={`${i}`}
          item={item}
          routes={routes}
          itemImageComponent={itemImageComponent}
          itemTitleComponent={itemTitleComponent}
          pushRoute={pushRoute}
          imageType={'thumbnail'}
          showCategoryName={true}
          showPlayIcon={true}
          // whether to maintain aspect ratio 16/9
          maintainImageAspectRatio={maintainImageAspectRatio}
          showDuration={props.showDuration}
          showReleaseYear={props.showReleaseYear}
          showPublishedDate={props.showPublishedDate}
          hideDescription={props.hideDescription}
        />
      );
    });
  };

  // Choose child component for title
  const TitleComponent = components[categoryTitleComponent];

  return (
    <div className={classes.chosenCategoryGrid}>
      {items && (
        <TitleComponent
          title={categoryName}
          styles={{
            justifyContent: 'center',
            paddingBottom: '50px',
          }}
          // for Category2
          item={chosenCategory}
          extraClassname={true}
        />
      )}

      <div
        className={classes.ViewedSectionSecondary__3videos}
        style={props.styles?.categoryGridContainer}
      >
        {items ? renderChosenCategoryGrid() : null}
      </div>
    </div>
  );
};

export default RenderChosenCategoryGrid;
